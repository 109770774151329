let redirectUri = replaceLocalhost(process.env.VUE_APP_REDIRECTURI)
let backofficeRootUri = replaceLocalhost(process.env.VUE_APP_BACKOFFICEROOTURI)
let tokenExchangeUri = replaceLocalhost(process.env.VUE_APP_TOKENEXCHANGEURI)
let clientId = replaceLocalhost(process.env.VUE_APP_CLIENTID)
let signInUri = replaceLocalhost(process.env.VUE_APP_SIGNINROOTURI)

function replaceLocalhost(uri){
    return uri.replace('localhost', window.location.hostname)
}

export function fetchProfile() {
    return fetch(`${backofficeRootUri}/me`, {
        method: 'GET',
        credentials: "include",
        headers: {
            'Content-Type': 'application/json' // or 'application/x-www-form-urlencoded' depending on your server setup
        },
    });
}

export function getEvents() {
    return fetch(`${backofficeRootUri}/events`, {
        method: 'GET',
        credentials: "include",
        headers: {
            'Content-Type': 'application/json' // or 'application/x-www-form-urlencoded' depending on your server setup
        },
    });
}

export function fetchDeposits() {
    return fetch(`${backofficeRootUri}/afspraken`, {
        method: 'GET',
        credentials: "include",
        headers: {
            'Content-Type': 'application/json' // or 'application/x-www-form-urlencoded' depending on your server setup
        },
    });
}
export function fetchKlanten(sequence) {
    const uri = sequence ? `${backofficeRootUri}/klanten?expectedSequence=${sequence}` : `${backofficeRootUri}/klanten`
    return fetch(uri,   {
        method: 'GET',
        credentials: "include",
        headers: {
            'Content-Type': 'application/json' // or 'application/x-www-form-urlencoded' depending on your server setup
        },
    });
}
export function fetchProject(id) {
    return fetchWithRetry(`${backofficeRootUri}/projecten/${id}`, {
        method: 'GET',
        credentials: "include",
        headers: {
            'Content-Type': 'application/json' // or 'application/x-www-form-urlencoded' depending on your server setup
        },
    }, 5,600);
}
export function fetchAfspraken(projectId) {
    return fetchWithRetry(`${backofficeRootUri}/projecten/${projectId}/afspraken`, {
        method: 'GET',
        credentials: "include",
        headers: {
            'Content-Type': 'application/json' // or 'application/x-www-form-urlencoded' depending on your server setup
        },
    }, 5,600);
}
export function fetchHistoriek(id) {
    return fetchWithRetry(`${backofficeRootUri}/projecten/${id}/historiek`, {
        method: 'GET',
        credentials: "include",
        headers: {
            'Content-Type': 'application/json' // or 'application/x-www-form-urlencoded' depending on your server setup
        },
    }, 5,600);
}

export function postDeposit(nieuwVoorschot) {
    return fetch(`${backofficeRootUri}/projecten`, {
        method: 'POST',
        body: JSON.stringify(nieuwVoorschot),
        credentials: "include",
        headers: {
            'Content-Type': 'application/json' // or 'application/x-www-form-urlencoded' depending on your server setup
        },
    });
}
export function postKlant(klant) {
    return fetch(`${backofficeRootUri}/klanten`, {
        method: 'POST',
        body: JSON.stringify(klant),
        credentials: "include",
        headers: {
            'Content-Type': 'application/json' // or 'application/x-www-form-urlencoded' depending on your server setup
        },
    });
}
export function exchangeToken(code){
    return fetch(tokenExchangeUri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
    body: new URLSearchParams({
          grant_type: 'authorization_code',
        client_id: clientId,
        code: code,
        redirect_uri: redirectUri
    })
    })
}

export function login(data) {
    return fetch(`${backofficeRootUri}/token`, {
        method: 'POST',
        credentials: "include",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${data.id_token}`
        },
        body: JSON.stringify({access: data.access_token, id: data.id_token}) // If using 'application/x-www-form-urlencoded', you need to change the formatting of the body
    });
}

async function fetchWithRetry(url, options = {}, retries = 3, delay = 1000) {
    for (let attempt = 0; attempt < retries; attempt++) {
        try {
            const response = await fetch(url, options);
            if (response.ok) {
                return response; // Resolve with the response if it's ok
            }
            // If the response is not ok, wait and then retry
            await new Promise(resolve => setTimeout(resolve, delay));
        } catch (error) {
            // If fetch itself fails (e.g., network error), wait and then retry unless it's the last attempt
            if (attempt === retries - 1) throw error;
            await new Promise(resolve => setTimeout(resolve, delay));
        }
    }
    throw new Error('Failed to fetch after multiple attempts');
}

export function signInWithGoogle() {
    window.location = signInUri + redirectUri;
}