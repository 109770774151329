import { defineStore } from 'pinia'
import {ref} from "vue";
import {exchangeToken, fetchProfile, login} from "@/lib/api";
import {useNotificationStore} from "@/stores/notifications";

export const useUserStore = defineStore('user', () => {
    const notificationsStore = useNotificationStore()
    const isLoggedIn = ref(false)
    const user = ref({
        name: '',
        email: '',
        imageUrl: '',
    })

    async function loadUserProfile() {
        try {
            const response = await fetchProfile()
            if(!response.ok){
                isLoggedIn.value = false
                return false;
            }

            const data = await response.json()
            isLoggedIn.value = true
            user.value.name = data.name
            user.value.email = data.email
            user.value.imageUrl = data.imageUrl

            notificationsStore.addSuccess(`Welkom terug, ${user.value.name}`, 'Blij je te zien!')

            return true
        }
        catch (e){
            notificationsStore.addError('Fout bij het aanmelden', e)
            return false
        }
    }

    async function exchangeCode(code: string | null){
        if(code && !isLoggedIn.value){
        console.log('Authorization code:', code);

        try{
            const response = await exchangeToken(code)
            const tokenJson = await response.json();
            console.log(tokenJson)
            await login(tokenJson)
            isLoggedIn.value = true;
            return true;
        }
        catch(e){
            notificationsStore.addError('Fout bij het aanmelden', e)

            return false;
        }
    } else {
        // The 'code' query parameter is not present in the URL
            notificationsStore.addError('Fout bij het aanmelden', 'Geen authorisatie code aanwezig in de url')
    }

}

    return { isLoggedIn, user, loadUserProfile, exchangeCode }
})
