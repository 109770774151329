<template>
    <div class="grid grid-cols-12 gap-x-0">
      <div class="mt-10 text-center col-start-1 col-end-13 row-start-1    mt-9">
        <div class="flex items-center text-gray-900">
          <button type="button" @click.prevent.stop="goToPreviousMonth" class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
            <span class="sr-only">Previous month</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <div class="flex-auto text-sm font-semibold">{{ currentDate.format('MMMM') }} {{currentYear}}</div>
          <button type="button" @click.prevent.stop="goToNextMonth" class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
            <span class="sr-only">Next month</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
          <div>S</div>
        </div>
        <div class="group isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
          <button @click.prevent.stop="pickDate(day)" v-for="(day, dayIdx) in days" :key="day.date" type="button" :class="['py-1.5 hover:bg-gray-100 focus:z-10', day.isCurrentMonth ? 'bg-white' : 'bg-gray-50', (day.isSelected || day.isToday) && 'font-semibold', day.isSelected && 'text-white', !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900', !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400', day.isToday && !day.isSelected && 'text-indigo-600', dayIdx === 0 && 'rounded-tl-lg', dayIdx === 6 && 'rounded-tr-lg', dayIdx === days.length - 7 && 'rounded-bl-lg', dayIdx === days.length - 1 && 'rounded-br-lg']">
            <time :datetime="day.date" aria-label="test"
                  :class="['mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                            day.isSelected && day.isToday && 'bg-indigo-600',
                            day.isSelected && !day.isToday && 'bg-gray-900']">
              {{ day.date.split('-').pop().replace(/^0/, '') }}<span v-for="appointment in day.appointments" :key="appointment">.</span></time>
          </button>
        </div>
      </div>
      </div>
  <ol class="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
    <li v-for="appointment in selectedDateAppointments" :key="appointment.id" class="relative flex space-x-6 py-6 xl:static">
       <div class="flex-auto">
        <h3 class="pr-10 font-semibold text-gray-900 xl:pr-0">{{ appointment.klantVoornaam }} {{ appointment.klantNaam }} - {{appointment.projectNaam}}</h3>
        <dl class="mt-2 flex flex-col text-gray-500 xl:flex-row">
          <div class="flex items-start space-x-3">
            <dt class="mt-0.5">
              <span class="sr-only">Date</span>
              <CalendarIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd>
              <time :datetime="appointment.datetime">{{ appointment.afspraakDate }} </time>
              <span> van </span>
              <time>{{  dayjs(appointment.afspraakStart, 'HH:mm:ss').format('HH:mm')}} </time>
              <span> tot </span>
              <time>{{  dayjs(appointment.afspraakEinde, 'HH:mm:ss').format('HH:mm')}} </time>

            </dd>
          </div>
          <div class="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
            <dt class="mt-0.5">
              <span class="sr-only">Location</span>
              <MapPinIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd>{{ appointment.location }}</dd>
          </div>
        </dl>
      </div>
    </li>
  </ol>

</template>

<script setup>
import {ref, computed, defineEmits} from 'vue';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
    CalendarIcon
} from '@heroicons/vue/20/solid'
import {useDashboardStore} from "@/stores/dashboard";


const emits = defineEmits(['picked']);
const dashboardStore = useDashboardStore();

// Extend day.js with necessary plugins
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

// Reactive data for the current month and year
const selectedDate = ref(null);
const selectedDateAppointments = computed(() => {
  if(!selectedDate.value)
    return [];
  return dashboardStore.aantalAfsprakenPerDag[selectedDate.value]?.afspraken ?? []
})
const currentDate = ref(dayjs());
const currentMonth = computed(() => currentDate.value.month());
const currentYear = computed(() => currentDate.value.year());


// Compute the days to be displayed in the calendar
const days = computed(() => {
  const startDay = currentDate.value.startOf('month').startOf('week');
  const endDay = currentDate.value.endOf('month').endOf('week');
  let date = startDay;
  const days = [];

  console.log('days')


  while (date.isBefore(endDay)) {
    let formattedDate = date.format('YYYY-MM-DD');

    days.push({
      date: formattedDate,
      isCurrentMonth: date.month() === currentMonth.value,
      isToday: date.isSame(dayjs(), 'day'),
      isSelected: date.isSame(selectedDate.value), // Modify as needed to mark selected dates,
      appointments: dashboardStore.aantalAfsprakenPerDag[formattedDate]?.afspraken ?? [],
    });
    date = date.add(1, 'day');
  }

  return days;
});

// Methods to navigate between months
function goToPreviousMonth() {
  currentDate.value = currentDate.value.subtract(1, 'month');
}

function goToNextMonth() {
  currentDate.value = currentDate.value.add(1, 'month');
}

function pickDate(date){
  emits('picked', date);
  selectedDate.value = date.date;
}
</script>