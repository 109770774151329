<!--
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <form v-show="userStore.isLoggedIn" @submit.prevent="onSubmit">
    <div class="space-y-12">
      <div class="border-b border-white/10 pb-12">
        <h2 class="text-base font-semibold leading-7">Nieuw project</h2>
        <p class="mt-1 text-sm leading-6 text-gray-400">Het begin van een transformatie.</p>

        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="sm:col-span-4">

              <Listbox v-model="nieuwProject.klant" as="div">
              <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Klant</ListboxLabel>
              <div class="relative mt-2 flex">
                <ListboxButton
                    class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  <span class="block truncate">{{ nieuwProject.klant.voornaam }} {{ nieuwProject.klant?.naam }}</span>
                  <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon aria-hidden="true" class="h-5 w-5 text-gray-400"/>
                  </span>
                </ListboxButton>
                <button class="m-0.5 p ml-2 rounded-full bg-indigo-600 p-1.5 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        type="button"
                        @click.prevent="showNieuweKlant">
                  <PlusIcon aria-hidden="true" class="h-5 w-5"/>
                </button>
                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                            leave-to-class="opacity-0">
                  <ListboxOptions
                      class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <ListboxOption v-for="person in klantenStore.klanten" :key="person.id" v-slot="{ active, selected }" :value="person"
                                   as="template">
                      <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
                            person.voornaam
                          }} {{
                            person.naam
                          }}</span>

                        <span v-if="selected"
                              :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon aria-hidden="true" class="h-5 w-5"/>
              </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>

            <div v-show="v$.klant.$error" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon aria-hidden="true" class="h-5 w-5 text-red-500"/>
            </div>
            <p v-for="error of v$.klant.$errors"
               id="klant-error" :key="error.$uid" class="mt-2 text-sm text-red-600">{{ error.$message }}</p>
          </div>
        </div>
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="sm:col-span-4">
            <label for="voornaam" class="block text-sm font-medium leading-6 text-gray-900">Projectnaam</label>
            <div class="mt-2">
              <input id="voornaam" v-model="nieuwProject.projectnaam" @blur="v$.projectnaam.$touch" name="text" type="text" autocomplete="projectnaam"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                     :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-red-500': v$.projectnaam.$error }"
                     placeholder="" />
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon v-show="v$.projectnaam.$error" class="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>
            </div>
            <p v-for="error of v$.projectnaam.$errors"
               :key="error.$uid" class="mt-2 text-sm text-red-600" id="projectnaam-error">{{ error.$message }}</p>
          </div>
        </div>
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="sm:col-span-4">
            <label for="description" class="block text-sm font-medium leading-6 text-gray-900">Opmerkingen</label>
            <div class="mt-2">
              <textarea id="description" v-model="nieuwProject.opmerkingen" name="description" rows="4" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
        </div>
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div class="sm:col-span-4">
          <label for="description" class="block text-sm font-medium leading-6 text-gray-900">Afspraken</label>
          <div class="text-center" v-show="nieuwProject.afspraken.length === 0">
            <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
            </svg>
            <h3 class="mt-2 text-sm font-semibold text-gray-900">Nog geen afspraken</h3>
            <p class="mt-1 text-sm text-gray-500">Voeg een afspraak toe.</p>
            <div class="mt-6">
              <button
                  @click.prevent="showRegistreerAfspraak"
                  type="button" class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <PlusIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                Afspraak toevoegen
              </button>
            </div>
          </div>
          <ul role="list" class="divide-y divide-gray-100" v-show="nieuwProject.afspraken.length > 0">
              <li v-for="afspraak in nieuwProject.afspraken" :key="afspraak.id" class="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap">
                <div>
                  <p class="text-sm font-semibold leading-6 text-gray-900">
                    <a :href="afspraak.href" class="hover:underline">{{ afspraak.datum }}</a>
                  </p>
                  <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                    <p>
                      <a class="hover:underline">{{ afspraak.van }} tot {{afspraak.tot}}</a>
                    </p>
                    <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                      <circle cx="1" cy="1" r="1" />
                    </svg>
                    <p>
                      <span>{{ afspraak.voorschotbedrag }} EUR</span>
                    </p>
                  </div>
                </div>
                <dl class="flex w-full flex-none justify-between gap-x-8 sm:w-auto">
                  <div class="flex w-16 gap-x-2.5">
                    <button @click.prevent="nieuwProjectStore.verwijderAfspraak(afspraak.id)" >
                      <span class="sr-only">Verwijder afspraak</span>
                      <XMarkIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
                    </button>
                  </div>
                </dl>
              </li>
            </ul>
          <div class="mt-6" v-show="nieuwProject.afspraken.length > 0">
            <button
                @click.prevent="showRegistreerAfspraak"
                type="button" class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <PlusIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              Afspraak toevoegen
            </button>
          </div>

        </div>
      </div>
      </div>
    </div>
    <div class="mt-6 flex items-center justify-end gap-x-6">
      <button class="text-sm font-semibold leading-6 text-gray-900" type="button">Annuleren</button>
      <button
          @click.prevent="onSubmit"
          class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              type="submit">
        Project starten
      </button>
    </div>

  </form>
</template>

<script setup>

import {useUserStore} from "@/stores/user";
import {ExclamationCircleIcon, XMarkIcon} from "@heroicons/vue/24/outline";
import {onMounted, toRefs} from "vue";
import {Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions} from '@headlessui/vue'
import {CheckIcon, ChevronUpDownIcon, PlusIcon} from '@heroicons/vue/20/solid'
import {useKlantenStore} from "@/stores/klanten";
import {useNieuwProjectStore} from "@/stores/nieuwProject";
import {useAfspraakRegistratieStore} from "@/stores/afspraakRegistratie";

const userStore = useUserStore();
const nieuwProjectStore = useNieuwProjectStore();
const klantenStore = useKlantenStore();
const afspraakStore = useAfspraakRegistratieStore();
const {nieuwProject, v$} = toRefs(nieuwProjectStore)
onMounted(async () => {
   await klantenStore.loadKlanten(null);
})

async function showRegistreerAfspraak(){
  console.log('show')
  await afspraakStore.startAfspraakRegistratie(afspraak => nieuwProjectStore.voegAfspraakToe(afspraak));
}
async function showNieuweKlant(){
  await klantenStore.startKlantRegistratie(klant => nieuwProjectStore.nieuwProject.klant = klant);
}

async function onSubmit() {
await nieuwProjectStore.registreerNieuwProject()
}

</script>