import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  role: "list",
  class: "divide-y divide-gray-100"
}
const _hoisted_2 = { class: "flex min-w-0 gap-x-4" }
const _hoisted_3 = { class: "min-w-0 flex-auto" }
const _hoisted_4 = { class: "text-sm font-semibold leading-6 text-gray-900" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "mt-1 flex text-xs leading-5 text-gray-500" }
const _hoisted_7 = { class: "relative truncate" }
const _hoisted_8 = { class: "flex shrink-0 items-center gap-x-4" }
const _hoisted_9 = { class: "hidden sm:flex sm:flex-col sm:items-end" }
const _hoisted_10 = { class: "text-sm leading-6 text-gray-900" }
const _hoisted_11 = {
  key: 0,
  class: "mt-1 flex items-center gap-x-1.5"
}
const _hoisted_12 = { class: "text-xs leading-5 text-gray-500" }
const _hoisted_13 = {
  key: 1,
  class: "mt-1 flex items-center gap-x-1.5"
}
const _hoisted_14 = { class: "text-xs leading-5 text-gray-500" }

import {computed, onMounted, watch} from "vue";
import {useUserStore} from "@/stores/user";
import {useDateFormat} from "@/lib/useDateFormat";
import {ChevronRightIcon} from "@heroicons/vue/16/solid";
import {useDashboardStore} from "@/stores/dashboard";
// import {useRouter} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const userStore = useUserStore();
const depositsStore = useDashboardStore();
// const router = useRouter();

const depositsWithFormattedDates = computed(() => {
  return depositsStore.volgendeAfspraken.map(afspraak => ({
    ...afspraak,
    afspraakGemaaktOpFormatted: useDateFormat(afspraak.afspraakGemaaktOp),
    afspraakDateFormatted: useDateFormat(afspraak.afspraakDate)
    // voorschotBetaaldOpFormatted: voorschot.voorschotBetaald ? useDateFormat(voorschot.voorschotBetaaldOp).formattedDate.value : '',
    // formulierIngediendOpFormatted: useDateFormat(voorschot.formulierIngediendOp).fromNow.value,
  }));
});


const isLoggedIn = computed(() => userStore.isLoggedIn);

// Watcher that reacts to changes in isLoggedIn
watch(isLoggedIn, async (newValue) => {
  if (newValue) {
    await depositsStore.loadVolgendeAfspraken();
  }
});

onMounted(async () => {
  console.log('mounted', isLoggedIn.value, userStore.isLoggedIn);

  if(isLoggedIn.value){
    console.log('fetching', isLoggedIn.value, userStore.isLoggedIn);
    await depositsStore.loadVolgendeAfspraken();

  }
})

// async function goToDetail(id: string){
//   console.log(id)
//   await router.push({ name: 'Project', params: {id:id}})
// }

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "text-3xl mb-5" }, "Volgende afspraken", -1)),
    _withDirectives(_createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(depositsWithFormattedDates.value, (voorschot) => {
        return (_openBlock(), _createElementBlock("li", {
          key: voorschot.afspraakId,
          class: "relative justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6"
        }, [
          _createVNode(_component_router_link, {
            class: "flex justify-between",
            to: {name: 'Project', params: {id: voorschot.projectId}}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("p", _hoisted_4, [
                    _createElementVNode("a", {
                      href: voorschot.href
                    }, [
                      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "absolute inset-x-0 -top-px bottom-0" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(voorschot.klantVoornaam) + " " + _toDisplayString(voorschot.klantNaam) + " - " + _toDisplayString(voorschot.projectNaam), 1)
                    ], 8, _hoisted_5)
                  ]),
                  _createElementVNode("p", _hoisted_6, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(voorschot.afspraakDateFormatted.naturalDate.value) + " (" + _toDisplayString(voorschot.afspraakDateFormatted.fromNow.value) + ")", 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("p", _hoisted_10, _toDisplayString(voorschot.voorschotbedrag) + " EUR", 1),
                  (voorschot.voorschotBetaald)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex-none rounded-full bg-emerald-500/20 p-1" }, [
                          _createElementVNode("div", { class: "h-1.5 w-1.5 rounded-full bg-emerald-500" })
                        ], -1)),
                        _createElementVNode("p", _hoisted_12, "Voorschot betaald op " + _toDisplayString(voorschot.voorschotBetaaldOpFormatted), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex-none rounded-full bg-red-500/20 p-1" }, [
                          _createElementVNode("div", { class: "h-1.5 w-1.5 rounded-full bg-red-500" })
                        ], -1)),
                        _createElementVNode("p", _hoisted_14, "Voorschot open sinds " + _toDisplayString(voorschot.afspraakGemaaktOpFormatted.formattedDate.value), 1)
                      ]))
                ]),
                _createVNode(_unref(ChevronRightIcon), {
                  class: "h-5 w-5 flex-none text-gray-400",
                  "aria-hidden": "true"
                })
              ])
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ], 512), [
      [_vShow, _unref(userStore).isLoggedIn]
    ])
  ], 64))
}
}

})