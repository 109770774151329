<script setup lang="ts">
import {computed, onMounted, watch} from "vue";
import {useUserStore} from "@/stores/user";
import {useDateFormat} from "@/lib/useDateFormat";
import {ChevronRightIcon} from "@heroicons/vue/16/solid";
import {useDashboardStore} from "@/stores/dashboard";
// import {useRouter} from "vue-router";

const userStore = useUserStore();
const depositsStore = useDashboardStore();
// const router = useRouter();

const depositsWithFormattedDates = computed(() => {
  return depositsStore.volgendeAfspraken.map(afspraak => ({
    ...afspraak,
    afspraakGemaaktOpFormatted: useDateFormat(afspraak.afspraakGemaaktOp),
    afspraakDateFormatted: useDateFormat(afspraak.afspraakDate)
    // voorschotBetaaldOpFormatted: voorschot.voorschotBetaald ? useDateFormat(voorschot.voorschotBetaaldOp).formattedDate.value : '',
    // formulierIngediendOpFormatted: useDateFormat(voorschot.formulierIngediendOp).fromNow.value,
  }));
});


const isLoggedIn = computed(() => userStore.isLoggedIn);

// Watcher that reacts to changes in isLoggedIn
watch(isLoggedIn, async (newValue) => {
  if (newValue) {
    await depositsStore.loadVolgendeAfspraken();
  }
});

onMounted(async () => {
  console.log('mounted', isLoggedIn.value, userStore.isLoggedIn);

  if(isLoggedIn.value){
    console.log('fetching', isLoggedIn.value, userStore.isLoggedIn);
    await depositsStore.loadVolgendeAfspraken();

  }
})

// async function goToDetail(id: string){
//   console.log(id)
//   await router.push({ name: 'Project', params: {id:id}})
// }
</script>

<template>
  <h1 class="text-3xl mb-5">Volgende afspraken</h1>
  <ul role="list" v-show="userStore.isLoggedIn" class="divide-y divide-gray-100">
      <li v-for="voorschot in depositsWithFormattedDates" :key="voorschot.afspraakId"
          class="relative justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
        <router-link class="flex justify-between" :to="{name: 'Project', params: {id: voorschot.projectId}}">

        <div class="flex min-w-0 gap-x-4">
            <div class="min-w-0 flex-auto">
          <p class="text-sm font-semibold leading-6 text-gray-900">
            <a :href="voorschot.href">
              <span class="absolute inset-x-0 -top-px bottom-0" />
              {{ voorschot.klantVoornaam }} {{ voorschot.klantNaam}} - {{ voorschot.projectNaam}}
            </a>
          </p>
          <p class="mt-1 flex text-xs leading-5 text-gray-500">
            <span class="relative truncate">{{ voorschot.afspraakDateFormatted.naturalDate.value }} ({{voorschot.afspraakDateFormatted.fromNow.value}})</span>
          </p>
        </div>
      </div>

        <div class="flex shrink-0 items-center gap-x-4">

        <div class="hidden sm:flex sm:flex-col sm:items-end">
          <p class="text-sm leading-6 text-gray-900">{{ voorschot.voorschotbedrag }} EUR</p>
          <div v-if="voorschot.voorschotBetaald" class="mt-1 flex items-center gap-x-1.5">
            <div class="flex-none rounded-full bg-emerald-500/20 p-1">
              <div class="h-1.5 w-1.5 rounded-full bg-emerald-500" />
            </div>
            <p class="text-xs leading-5 text-gray-500">Voorschot betaald op {{voorschot.voorschotBetaaldOpFormatted}}</p>
          </div>
        <div v-else class="mt-1 flex items-center gap-x-1.5">
            <div class="flex-none rounded-full bg-red-500/20 p-1">
              <div class="h-1.5 w-1.5 rounded-full bg-red-500" />
            </div>
            <p class="text-xs leading-5 text-gray-500">Voorschot open sinds {{voorschot.afspraakGemaaktOpFormatted.formattedDate.value }}</p>
          </div>
        </div>

        <ChevronRightIcon class="h-5 w-5 flex-none text-gray-400"  aria-hidden="true" />
      </div>
        </router-link>
      </li>
  </ul>

</template>

<style scoped>
</style>