// Imports section
import {defineStore} from 'pinia';
import {computed, ComputedRef, reactive, ref} from "vue";
import {fetchProject, fetchDeposits, fetchHistoriek, postDeposit} from "@/lib/api";
import {useNotificationStore} from "@/stores/notifications";
import {validators} from "@/lib/validators";
import {useVuelidate} from "@vuelidate/core";
import {Project} from "@/stores/models/project";

// Store definition
export const useDepositsStore = defineStore('deposits', () => {
    // Store state
    const notificationsStore = useNotificationStore();
    const voorschottenRaw = ref([]);
    const zoekbalk = ref("");
    const voorschot = ref({});
    const historiek = ref({
        gebeurtenissen: [],
        verwachtteGebeurtenissen: [],
    });

    // Default Project Factory
    const defaultProject: () => Project = () => ({
        klant: {
            id: '',
            voornaam: '',
            naam: '',
            email: '',
            opmerkingen: '',
        },
        projectnaam: '',
        opmerkingen: '',
        afspraken: [],
    });

    const nieuwProject: Project = reactive(defaultProject());

    // Validation rules
    const rules = {
        klant: {
            required: validators.required
        },
        projectnaam: {
            required: validators.required
        }
    };

    const v$ = useVuelidate(rules, nieuwProject, { $scope: 'project' });

    // Store actions and utility functions
    async function loadDeposits() {
        try {
            const response = await fetchDeposits();
            if (!response.ok) return;
            const data = await response.json();
            voorschottenRaw.value = data;
        } catch (e) {
            notificationsStore.addError('Fout bij het ophalen van de voorschotten', e);
        }
    }

    async function loadDeposit(id: string) {
        try {
            voorschot.value = defaultProject();
            const response = await fetchProject(id);
            if (!response.ok) return;
            voorschot.value = await response.json();
        } catch (e) {
            notificationsStore.addError('Fout bij het ophalen van het voorschot', e);
        }
    }

    async function loadHistoriek(id: string) {
        try {
            const response = await fetchHistoriek(id);
            historiek.value = await response.json();
        } catch (e) {
            notificationsStore.addError('Historiek ophalen mislukt', e);
        }
    }

    async function registreerNieuwProject(nieuwProject: any) {
        try {
            const response = await postDeposit(nieuwProject);
            nieuwProject.value = defaultProject();
            notificationsStore.addSuccess('Voorschot toegevoegd', 'Het voorschot is succesvol toegevoegd.');
            return response.json();
        } catch (e) {
            notificationsStore.addError('Voorschot toevoegen mislukt', e);
        }
    }

    // Computed properties
    const voorschotten: ComputedRef<any> = computed(() => {
        if (!zoekbalk.value) {
            return voorschottenRaw.value; // If zoekbalk is empty, return all voorschotten
        }
        return voorschottenRaw.value.filter((voorschot: any) => {
            return voorschot.naam.toLowerCase().includes(zoekbalk.value.toLowerCase());
        });
    });

    // Store export
    return {
        voorschotten,
        historiek,
        nieuwProject,
        voorschot,
        zoekbalk,
        loadDeposits,
        loadDeposit,
        loadHistoriek,
        registreerNieuwProject
    }
});
