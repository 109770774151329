// Imports section
import {defineStore} from 'pinia';
import {ref} from "vue";
import {fetchProject, fetchAfspraken} from "@/lib/api";
import {useNotificationStore} from "@/stores/notifications";

// Store definition
export const useProjectDetailStore = defineStore('project-detail', () => {
    // Store state
    const notificationsStore = useNotificationStore();
    const project = ref({});
    const afspraken = ref([]);
    function defaultProject() {
        return {
            projectNaam: '',
            klantNaam: '',
            klantVoornaam: ''
        }
    }
    async function loadProject(id: string) {
        try {
            project.value = defaultProject();
            const response = await fetchProject(id);
            if (!response.ok) return;
            project.value = await response.json();
        } catch (e) {
            notificationsStore.addError('Fout bij het ophalen van het voorschot', e);
        }
    }

    async function loadAfspraken(projectId: string) {
        try {
            afspraken.value = [];
            const response = await fetchAfspraken(projectId);
            if (!response.ok) return;
            afspraken.value = (await response.json()).afspraken;
        } catch (e) {
            notificationsStore.addError('Fout bij het ophalen van het voorschot', e);
        }
    }

    // async function loadHistoriek(id: string) {
    //     try {
    //         const response = await fetchHistoriek(id);
    //         historiek.value = await response.json();
    //     } catch (e) {
    //         notificationsStore.addError('Historiek ophalen mislukt', e);
    //     }
    // }
    // Store export

    return {
        project,
        afspraken,
        loadProject,
        loadAfspraken
    }
});
