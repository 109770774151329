import {defineStore} from "pinia";
import {ref} from "vue";
import {exchangeToken, fetchProfile, login} from "@/lib/api";
class Notification{
    id: number;
    type: string;
    title: string;
    message: string;
    persistent: boolean;
    constructor(id: number, type: string, title: string, message: string, persistent = false) {
        this.id = id;
        this.type = type;
        this.title = title;
        this.message = message;
        this.persistent = persistent;
    }

    static fromError(error: Error): Notification {
        const id = Date.now(); // Unique ID for each notification
        const title = "Error";
        const message = error.message || "An unknown error occurred";
        return new Notification(id, "error", title, message);
    }

    // New method for handling generic messages
    static createMessage(type: string, title: string, message: string, persistent = false): Notification {
        const id = Date.now();
        return new Notification(id, type, title, message, persistent);
    }
}
export const useNotificationStore = defineStore('notifications', () => {
    const notifications = ref<Notification[]>([]);

    function addNotification(notification: Notification) {
        const id = Date.now(); // Unique ID for each notification
        notifications.value.push(notification);

        if(notification.persistent)
            return;

        setTimeout(() => {
            notifications.value = notifications.value.filter(notification => notification.id !== id);
        }, 5000); // Remove notification after 3 seconds
    }

    function addSuccess(title: string, message: string) {
        addNotification(Notification.createMessage("success", title, message));
    }

    function addError(title: string, error: any) {
        let message: string;
        if (error instanceof Error) {
                message = error.message;
        } else if (typeof error === 'string') {
            // Directly a string, use it as the message
            message = error;
        } else if (typeof error === 'object' && error !== null && 'message' in error && typeof (error as any).message === 'string') {
            // It's an object with a message property
            message = (error as any).message;
        } else {
            // Fallback for other types or missing message
            message = "An unknown error occurred";
        }

        const notification = Notification.createMessage("error", title, message, true);
        addNotification(notification);
    }

    function clearNotification(id: number){
        notifications.value = notifications.value.filter(x => x.id !== id)
    }

    return { notifications, clearNotification, addSuccess, addError };
})
