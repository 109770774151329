<script setup lang="ts">
import {computed, onMounted, watch} from "vue";
import {useUserStore} from "@/stores/user";
import {ChevronRightIcon} from "@heroicons/vue/16/solid";
import {useKlantenStore} from "@/stores/klanten";
// import {useRouter} from "vue-router";

const userStore = useUserStore();
const klantenStore = useKlantenStore();
// const router = useRouter();

const isLoggedIn = computed(() => userStore.isLoggedIn);

// Watcher that reacts to changes in isLoggedIn
watch(isLoggedIn, async (newValue) => {
  if (newValue) {
    await klantenStore.loadKlanten();
  }
});

onMounted(async () => {
  await klantenStore.loadKlanten();
})

</script>

<template>
  <h1 class="text-3xl mb-5">Klanten</h1>
  <ul role="list" v-show="userStore.isLoggedIn" class="divide-y divide-gray-100">
    <li v-for="klant in klantenStore.klanten" :key="klant.email"
        class="relative justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
      <router-link class="flex justify-between" :to="{name: 'Klant', params: {id: klant.email}}">

        <div class="flex min-w-0 gap-x-4">
          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold leading-6 text-gray-900">
              <a :href="klant.href">
                <span class="absolute inset-x-0 -top-px bottom-0" />
                {{ klant.voornaam }} {{ klant.naam}}
              </a>
            </p>
          </div>
        </div>

        <div class="flex shrink-0 items-center gap-x-4">
          <ChevronRightIcon class="h-5 w-5 flex-none text-gray-400"  aria-hidden="true" />
        </div>
      </router-link>
    </li>
  </ul>

</template>

<style scoped>
</style>