<script lang="ts" setup>

import {fetchProjectionStates, resetData, rebuildProjecties} from "@/lib/adminApi";
import {onMounted, ref} from "vue";
import {useNotificationStore} from "@/stores/notifications";

const notificationStore = useNotificationStore();

const isLoading = ref(false);

const projections = ref([]);

onMounted(async () => {
  let response = await fetchProjectionStates();

  projections.value = await response.json();
})

async function onRebuildClick() {
  try {
    isLoading.value = true
    await rebuildProjecties()
    notificationStore.addSuccess('Rebuild projecties', 'De projecties zijn gerebuild')
  } catch (e) {
    notificationStore.addError('Projecties rebuilden is mislukt', e);
  } finally {
    isLoading.value = false;
  }
}

async function onResetClicked() {
  try {
    isLoading.value = true
    await resetData()
    notificationStore.addSuccess('Reset data', 'De data is gereset')
  } catch (e) {
    notificationStore.addError('Data resetten is mislukt', e);
  } finally {
    isLoading.value = false;
  }
}

</script>

<template>
  <div>
    <h2 class="text-sm font-medium text-gray-500">Pinned Projects</h2>
    <ul role="list" class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
      <li v-for="project in projections" :key="project.shardName" class="col-span-1 flex rounded-md shadow-sm">
        <div :class="'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'"></div>
        <div class="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
          <div class="flex-1 truncate px-4 py-2 text-sm">
            <a class="font-medium text-gray-900 hover:text-gray-600">{{ project.shardName }}</a>
            <p class="text-gray-500">{{ project.sequence }}</p>
          </div>
          <div class="flex-shrink-0 pr-2">
            <button type="button" class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span class="sr-only">Open options</span>
              <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="bg-white shadow sm:rounded-lg">
    <div class="px-4 py-5 sm:p-6">
      <h3 class="text-base font-semibold leading-6 text-gray-900">Alle projecties rebuilden</h3>
      <div class="mt-2 sm:flex sm:items-start sm:justify-between">
        <div class="max-w-xl text-sm text-gray-500">
          <p>Hier komen alle projectie statussen</p>
        </div>
        <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
          <button class="inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                  type="button"
                  @click.prevent="onRebuildClick">
                <span v-if="isLoading" class="inline-block">
      <svg class="animate-spin h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              fill="currentColor"></path>
      </svg>
    </span>
            <span>{{ isLoading ? 'Rebuilding...' : 'Rebuild projecties' }}</span>

          </button>
        </div>
      </div>
    </div>
    <div class="px-4 py-5 sm:p-6">
      <h3 class="text-base font-semibold leading-6 text-gray-900">Alle data resetten</h3>
      <div class="mt-2 sm:flex sm:items-start sm:justify-between">
        <div class="max-w-xl text-sm text-gray-500">
          <p>Pas op!</p>
        </div>
        <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
          <button class="inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                  type="button"
                  @click.prevent="onResetClicked">
                <span v-if="isLoading" class="inline-block">
      <svg class="animate-spin h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              fill="currentColor"></path>
      </svg>
    </span>
            <span>{{ isLoading ? 'Resetting...' : 'Reset data' }}</span>

          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>