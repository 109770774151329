import {defineStore} from 'pinia'
import {computed, Ref, ref} from "vue";
import {useNotificationStore} from "@/stores/notifications";
import {validators} from "@/lib/validators";
import {useVuelidate} from "@vuelidate/core";
import {fetchDeposits} from "@/lib/api";
import dayjs from "dayjs";
interface Afspraak{
    afspraakId: string,
    klantVoornaam: string,
    klantNaam: string,
    projectId: string,
    projectNaam: string,
    afspraakDate: string,
    afspraakStart: string,
    voorschotbedrag: string,
    voorschotBetaald: boolean,
    voorschotBetaaldOp: string,
    afspraakGemaaktOp: string
}
type AantalAfsprakenPerDag = {
    [date: string]: {
        count: number;
        afspraken: Afspraak[];
    };
};
export const useDashboardStore = defineStore('dashboard',
    () => {
        const notificationsStore = useNotificationStore();

        const afspraken: Ref<Afspraak[]> = ref([]);
        const volgendeAfspraken = computed(() =>
            afspraken.value.filter(afspraak => {
                    console.log(dayjs(afspraak.afspraakDate).isAfter(dayjs()), afspraak.afspraakDate, dayjs())
                    return dayjs(afspraak.afspraakDate).isAfter(dayjs());
                }
            )
        );
        const aantalAfsprakenPerDag = computed<AantalAfsprakenPerDag>(() => {
            return afspraken.value.reduce((acc: AantalAfsprakenPerDag, afspraak: Afspraak) => {
                const date = dayjs(afspraak.afspraakDate).format('YYYY-MM-DD');
                if (!acc[date]) {
                    acc[date] = { count: 1, afspraken: [afspraak] };
                } else {
                    acc[date].count++;
                    acc[date].afspraken.push(afspraak);
                }
                return acc;
            }, {});
        });
        async function loadVolgendeAfspraken() {
            console.log('fetching...')

            try {
                console.log('fetching...')

                const response = await fetchDeposits();

                if (!response.ok) return;
                const data = await response.json();
                afspraken.value = data;
            } catch (e) {
                console.log('fetching...', e)

                notificationsStore.addError('Fout bij het ophalen van de voorschotten', e);
            }
            console.log('fetching... done')

        }

        return {
            afspraken,
            volgendeAfspraken,
            aantalAfsprakenPerDag,
            loadVolgendeAfspraken
        }
    })
