import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  role: "list",
  class: "divide-y divide-gray-100"
}
const _hoisted_2 = { class: "flex min-w-0 gap-x-4" }
const _hoisted_3 = { class: "min-w-0 flex-auto" }
const _hoisted_4 = { class: "text-sm font-semibold leading-6 text-gray-900" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "flex shrink-0 items-center gap-x-4" }

import {computed, onMounted, watch} from "vue";
import {useUserStore} from "@/stores/user";
import {ChevronRightIcon} from "@heroicons/vue/16/solid";
import {useKlantenStore} from "@/stores/klanten";
// import {useRouter} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'KlantenView',
  setup(__props) {

const userStore = useUserStore();
const klantenStore = useKlantenStore();
// const router = useRouter();

const isLoggedIn = computed(() => userStore.isLoggedIn);

// Watcher that reacts to changes in isLoggedIn
watch(isLoggedIn, async (newValue) => {
  if (newValue) {
    await klantenStore.loadKlanten();
  }
});

onMounted(async () => {
  await klantenStore.loadKlanten();
})


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "text-3xl mb-5" }, "Klanten", -1)),
    _withDirectives(_createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(klantenStore).klanten, (klant) => {
        return (_openBlock(), _createElementBlock("li", {
          key: klant.email,
          class: "relative justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6"
        }, [
          _createVNode(_component_router_link, {
            class: "flex justify-between",
            to: {name: 'Klant', params: {id: klant.email}}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("p", _hoisted_4, [
                    _createElementVNode("a", {
                      href: klant.href
                    }, [
                      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "absolute inset-x-0 -top-px bottom-0" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(klant.voornaam) + " " + _toDisplayString(klant.naam), 1)
                    ], 8, _hoisted_5)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_unref(ChevronRightIcon), {
                  class: "h-5 w-5 flex-none text-gray-400",
                  "aria-hidden": "true"
                })
              ])
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ], 512), [
      [_vShow, _unref(userStore).isLoggedIn]
    ])
  ], 64))
}
}

})