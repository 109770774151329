import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {onMounted} from "vue";
import {useUserStore} from "@/stores/user";
import {useRouter} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallbackView',
  setup(__props) {

const router = useRouter();
onMounted(async () => {
  const queryParams = new URLSearchParams(window.location.search);

// eslint-disable-next-line no-undef
  const userStore = useUserStore();

  await userStore.exchangeCode(queryParams.get('code'))
  if(userStore.isLoggedIn)
    await router.push({path: '/'})
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("p", null, "Logging you in..."))
}
}

})