<script setup>

import {ExclamationCircleIcon, XMarkIcon} from "@heroicons/vue/24/outline";
import {Dialog, DialogTitle, DialogPanel, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {useKlantenStore} from "@/stores/klanten";
import {toRefs} from "vue";

const klantenStore = useKlantenStore();
const {
    v$,
    showKlantRegistratie,
    nieuwKlant
} = toRefs(klantenStore);
async function onSubmit() {
  await klantenStore.registreerKlant();
}

async function onCancel() {
  await klantenStore.annuleerKlantRegistratie();
}

</script>

<template>
  <TransitionRoot as="template" :show="showKlantRegistratie">
  <Dialog as="div" class="relative z-50">
    <div class="fixed inset-0" />

    <div @click="onCancel"
         class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <DialogPanel class="pointer-events-auto w-screen max-w-md">
              <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div class="h-0 flex-1 overflow-y-auto">
                  <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-base font-semibold leading-6 text-white">Nieuwe klant</DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" @click="onCancel">
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-indigo-300">A stranger's just a friend you haven't met.</p>
                    </div>
                  </div>
                  <div class="flex flex-1 flex-col justify-between">
                    <div class="divide-y divide-gray-200 px-4 sm:px-6">
                      <div class="space-y-6 pb-5 pt-6">
                        <div class="sm:col-span-4">
                          <label for="voornaam" class="block text-sm font-medium leading-6 text-gray-900">Voornaam</label>
                          <div class="mt-2">
                            <input id="voornaam" v-model="nieuwKlant.voornaam" @blur="v$.voornaam.$touch" name="text" type="text" autocomplete="voornaam"
                                   class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                                   :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-red-500': v$.voornaam.$error }"
                                   placeholder="" />
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <ExclamationCircleIcon v-show="v$.voornaam.$error" class="h-5 w-5 text-red-500" aria-hidden="true" />
                            </div>
                          </div>
                          <p v-for="error of v$.voornaam.$errors"
                             :key="error.$uid" class="mt-2 text-sm text-red-600" id="voornaam-error">{{ error.$message }}</p>
                        </div>
                        <div class="sm:col-span-4">
                          <label for="naam" class="block text-sm font-medium leading-6 text-gray-900">Naam</label>
                          <div class="mt-2">
                            <input id="naam" v-model="nieuwKlant.naam" @blur="v$.naam.$touch" name="text" type="text" autocomplete="naam"
                                   class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                                   :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-red-500': v$.naam.$error }"
                                   placeholder="" />
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <ExclamationCircleIcon v-show="v$.naam.$error" class="h-5 w-5 text-red-500" aria-hidden="true" />
                            </div>
                          </div>
                          <p v-for="error of v$.naam.$errors"
                             :key="error.$uid" class="mt-2 text-sm text-red-600" id="naam-error">{{ error.$message }}</p>
                        </div>

                        <div class="sm:col-span-4">
                          <label for="email" class="block text-sm font-medium leading-6 text-gray-900">E-mailadres</label>
                          <div class="mt-2">
                            <input id="email" v-model="nieuwKlant.email" @blur="v$.email.$touch" name="text" type="text" autocomplete="email"
                                   class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                                   :class="{ 'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-red-500': v$.email.$error }"
                                   placeholder="" />
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <ExclamationCircleIcon v-show="v$.email.$error" class="h-5 w-5 text-red-500" aria-hidden="true" />
                            </div>
                          </div>
                          <p v-for="error of v$.email.$errors"
                             :key="error.$uid" class="mt-2 text-sm text-red-600" id="email-error">{{ error.$message }}</p>
                        </div>
                        <div>
                          <label for="description" class="block text-sm font-medium leading-6 text-gray-900">Opmerkingen</label>
                          <div class="mt-2">
                            <textarea id="description" v-model="nieuwKlant.opmerkingen" name="description" rows="4" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-shrink-0 justify-end px-4 py-4">
                  <button type="button" class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click.prevent="onCancel">Cancel</button>
                  <button type="submit" class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click.prevent.stop="onSubmit">Save</button>
                </div>
              </form>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </div>
  </Dialog>
  </TransitionRoot>

</template>

<style scoped>

</style>