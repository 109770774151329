import { createApp } from 'vue'
import App from './App.vue'
import './assets/styles/index.css';
import HomeView from "@/views/HomeView.vue";
import CallbackView from "@/views/CallbackView.vue";
import {createRouter, createWebHistory} from "vue-router";
import {createPinia} from "pinia";
import NieuwProjectView from "@/views/NieuwProjectView.vue";
import {useUserStore} from "@/stores/user";
import NotFoundView from "@/views/NotFoundView.vue";
import ProjectView from "@/views/ProjectView.vue";
import AdminView from "@/views/AdminView.vue";

import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from "dayjs";
import EventsView from "@/views/EventsView.vue";
import KlantenView from "@/views/KlantenView.vue";

// Extend dayjs with the custom parse format plugin
dayjs.extend(customParseFormat);


const routes = [
    { path: '/', component: HomeView, name: 'Dashboard', meta: { requiresAuth: true }},
    { path: '/projecten/nieuw', name: 'Projecten', component: NieuwProjectView, meta: { requiresAuth: true },},
    { path: '/projecten/:id', name: 'Project', component: ProjectView, meta: { requiresAuth: true },},
    { path: '/google/auth', component: CallbackView },
    { path: '/admin', name: 'Admin', component: AdminView, meta: { requiresAuth: true } },
    { path: '/klanten', name: 'Klanten', component: KlantenView, meta: { requiresAuth: true } },
    { path: '/klanten/:id', name: 'Klant', component: KlantenView, meta: { requiresAuth: true } },
    { path: '/events', name: 'Events', component: EventsView, meta: { requiresAuth: true } },
    { path: '/404', name: '404', component: NotFoundView },
]

const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

const appBuilder = createApp(App);

appBuilder.use(createPinia())
appBuilder.use(router);

const userStore = useUserStore()

const userProfileLoaded = await userStore.loadUserProfile()

router.beforeEach((to, from, next) => {
    // Check if the route requires authentication
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // Assume `isLoggedIn` is a method to check login status
        if (!userStore.isLoggedIn) {
            // Redirect to the login page
            next({ name: '404' });
        } else {
            next(); // Proceed to route
        }
    } else {
        next(); // Does not require auth, make sure to always call next()
    }
});


const app = appBuilder.mount('#app')

