import { computed } from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import locale from 'dayjs/locale/nl-be';

dayjs.extend(relativeTime);
dayjs.locale('nl-be')
dayjs.locale(locale)

export function useDateFormat(date) {
    const formattedDate = computed(() => {
        return dayjs(date).format('DD MMMM, YYYY');
    });

    const naturalDate = computed(() => {
        return dayjs(date).format('dddd DD MMMM');
    });

    const fromNow = computed(() => {
        return dayjs(date).fromNow();
    });

    return { formattedDate, naturalDate, fromNow };
}

export function useDateTimeFormat(date) {
    const formattedDate = computed(() => {
        return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
    });

    return { formattedDate };
}
export function useTimeFormat(time) {
    const formatted = computed(() => {
        return dayjs(time, 'HH:mm:ss').format('HH:mm');
    });
    return { formatted };
}
