<template>
  <main>
    <h1 class="text-3xl mb-5">
      {{ projectDetailStore.project.klantVoornaam }}
      {{ projectDetailStore.project.klantNaam }}
      - {{ projectDetailStore.project.projectNaam }}
    </h1>
    <div class="text-gray-600 mb-5">
      {{projectDetailStore.project.projectOpmerkingen}}
    </div>
    <h1 class="text-2xl mt-10">
Afspraken
    </h1>
    <div class="mx-auto max-w-7xl px-4 py-5 sm:px-6 lg:px-8">
      <div class="mx-auto max-w-2xl items gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <div v-for="afspraak in projectDetailStore.afspraken" :key="afspraak.id" class="mb-10">
          <h2 class="sr-only">Summary</h2>
          <div class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
            <dl class="flex flex-wrap">
              <div class="flex-auto pl-6 pt-6">
                <dt class="text-sm font-semibold leading-6 text-gray-900"></dt>
                <dd class="mt-1 text-base font-semibold leading-6 text-gray-900">{{ useDateFormat(afspraak.afspraakDate).naturalDate.value }}</dd>
              </div>
              <div class="flex-none self-end px-6 pt-4">
                <dt class="sr-only">Status</dt>
                <dd v-if="afspraak?.voorschotBetaald ?? false"
                    class="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
                  Betaald
                </dd>
                <dd v-else class="rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-600/20">Openstaand</dd>
              </div>
              <div class="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                <dt class="flex-none">
                  <span class="sr-only">Client</span>
                  <CurrencyEuroIcon class="h-6 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd class="text-sm font-medium leading-6 text-gray-900">{{ afspraak?.voorschotbedrag }} EUR</dd>
              </div>
              <div class="mt-4 flex w-full flex-none gap-x-4 px-6">
                <dt class="flex-none">
                  <span class="sr-only">Due date</span>
                  <CalendarDaysIcon class="h-6 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd class="text-sm leading-6 text-gray-500">
                  <time datetime="2023-01-31">{{ useTimeFormat(afspraak.afspraakStart).formatted.value }} tot
                    {{ useTimeFormat(afspraak.afspraakEinde).formatted.value }}</time>
                </dd>
              </div>
              <div v-show="afspraak.afspraakOpmerkingen" class="mt-4 flex w-full flex-none gap-x-4 px-6">
              <dt class="flex-none">
                <span class="sr-only">Due date</span>
                <light-bulb-icon  class="h-6 w-5 text-gray-400" aria-hidden="true" />
              </dt>
                <dd class="text-sm font-medium leading-6 text-gray-900">{{ afspraak.afspraakOpmerkingen}}</dd>
            </div>
            </dl>
            <div class="mt-6 border-t border-gray-900/5 px-6 py-6">
              <a href="#" class="text-sm font-semibold leading-6 text-gray-900">Bekijk betaling <span aria-hidden="true">&rarr;</span></a>
            </div>
          </div>
        </div>
        <div class="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
          <div class="mx-auto max-w-2xl items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div class="">
              <ul role="list" class="-mb-8">
                <li v-for="(event, eventIdx) in timeline" :key="event.id">
                  <div class="relative pb-8">
                    <span v-if="eventIdx !== timeline.length - 1" class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                <div class="relative flex space-x-3">
                  <div>
                    <span :class="[event.iconBackground, 'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white']">
                      <component :is="event.icon" class="h-5 w-5 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div>
                      <p class="text-sm text-gray-500">
                        {{ event.content }} <a :href="event.href" class="font-medium text-gray-900">{{ event.target }}</a>
                      </p>
                    </div>
                    <div class="whitespace-nowrap text-right text-sm text-gray-500 group">
                      <time :datetime="event.datetime">{{ event.date }}</time>
                      <div class="absolute bottom-full mb-2 hidden group-hover:block p-2 min-w-max bg-gray-700 text-white text-sm rounded">
                        {{ event.datetime }}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import {CalendarDaysIcon, CurrencyEuroIcon, LightBulbIcon} from '@heroicons/vue/24/outline'
import {onMounted} from "vue";
import {useRoute} from "vue-router";
import {useProjectDetailStore} from "@/stores/projectDetail";
import {useDateFormat, useTimeFormat} from "@/lib/useDateFormat";

const projectDetailStore = useProjectDetailStore();
const route = useRoute();

// const timeline = computed(() => {
//   return projectDetailStore.historiek.gebeurtenissen.map((item, index) => ({
//     id: index + 1, // Assuming you want to start IDs at 1
//     content: item.omschrijving, // Map 'omschrijving' to 'content'
//     date: useDateFormat(item.tijdstip).fromNow.value,
//     datetime: useDateFormat(item.tijdstip).formattedDate.value,
//     icon: CheckIcon,
//     iconBackground: 'bg-green-500',
//     // Add any additional fixed or dynamic properties as needed
//   })).concat(projectDetailStore.historiek.verwachtteGebeurtenissen.map((item, index) => ({
//     id: index + 1, // Assuming you want to start IDs at 1
//     content: item.omschrijving, // Map 'omschrijving' to 'content'
//     date: item.tijdstip ? useDateFormat(item.tijdstip).fromNow.value : "",
//     datetime: useDateFormat(item.tijdstip).formattedDate.value,
//     icon: item.tijdstip ? CheckIcon : ClockIcon,
//     iconBackground: 'bg-gray-400',
//     // Add any additional fixed or dynamic properties as needed
//   })));
// });

onMounted(async () => {
  await projectDetailStore.loadProject(route.params.id);
  await projectDetailStore.loadAfspraken(route.params.id);
  // await projectDetailStore.loadHistoriek(route.params.id);
})
</script>
