// Imports section
import {defineStore} from 'pinia';
import {computed, ComputedRef, reactive, Ref, ref} from "vue";
import {fetchProject, fetchDeposits, fetchHistoriek, postDeposit} from "@/lib/api";
import {useNotificationStore} from "@/stores/notifications";
import {validators} from "@/lib/validators";
import {useVuelidate} from "@vuelidate/core";
import {Project} from "@/stores/models/project";
import {Afspraak} from "@/stores/models/afspraak";
import moment from "moment-timezone";
import {useRouter} from "vue-router";

// Store definition
export const useNieuwProjectStore = defineStore('nieuw-project', () => {
    // Store state
    const notificationsStore = useNotificationStore();
    const router = useRouter();

    const defaultProject: () => Project = () => ({
        klant: {
            id: '',
            voornaam: '',
            naam: '',
            email: '',
            opmerkingen: '',
        },
        projectnaam: '',
        opmerkingen: '',
        afspraken: [],
    });

    let nieuwProject: Project = reactive(defaultProject());

    // Validation rules
    const rules = {
        klant: {
            required: validators.required
        },
        projectnaam: {
            required: validators.required
        }
    };

    const v$ = useVuelidate(rules, nieuwProject);


    function voegAfspraakToe(afspraak: Afspraak){
      const maxId = nieuwProject.afspraken.length === 0 ?
          0 :
          Math.max(...nieuwProject.afspraken.map(obj => obj.id));

      const afspraakMetId = {...afspraak, id: maxId + 1 };

      nieuwProject.afspraken = [...nieuwProject.afspraken, afspraakMetId]
    }

    function verwijderAfspraak(afspraakId: number) {
        nieuwProject.afspraken = nieuwProject.afspraken.filter(x => x.id !== afspraakId);
    }

    async function registreerNieuwProject() {
        try {
            await v$.value.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (v$.value.$invalid)
                    return false

            const response = await postDeposit(nieuwProject);
            nieuwProject = defaultProject();
            v$.value.$reset()
            notificationsStore.addSuccess('Voorschot toegevoegd', 'Het voorschot is succesvol toegevoegd.');
            return response.json();
        } catch (e) {
            notificationsStore.addError('Voorschot toevoegen mislukt', e);
        }
    }

    return {
        v$,
        nieuwProject,
        voegAfspraakToe,
        verwijderAfspraak,
        registreerNieuwProject
    }
});
