<template>
  <div class="flow-root">
    <ul role="list" class="-mb-8">
      <li v-for="(activityItem, activityItemIdx) in activity" :key="activityItem.id">
        <div class="relative pb-8">
          <span v-if="activityItemIdx !== activity.length - 1" class="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
          <div class="relative flex items-start space-x-3">
              <div class="relative">
                <img class="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white" :src="activityItem.imageUrl" alt="" />

                <span class="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                  <ChatBubbleLeftEllipsisIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </div>
              <div class="min-w-0 flex-1">
                <div>
                  <div class="text-sm">
                    <a class="font-medium text-gray-900">{{ activityItem.name }}</a>
                  </div>
                  <p class="mt-0.5 text-sm text-gray-500">{{ useDateTimeFormat(activityItem.timestamp).formattedDate.value }}</p>
                </div>
                <div class="mt-2 text-sm text-gray-700">
                  <p>{{ toJSON(activityItem.data) }}</p>
                </div>
              </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/vue/20/solid'
import {getEvents} from "@/lib/api";
import {onMounted, ref} from "vue";
import {useDateTimeFormat} from "@/lib/useDateFormat";
import {toJSON} from "yaml/util";
const activity = ref([]);

onMounted(async () => {
  let response = await getEvents();
  activity.value = await response.json();

});

</script>