let backofficeRootUri = replaceLocalhost(process.env.VUE_APP_BACKOFFICEROOTURI)

function replaceLocalhost(uri){
    return uri.replace('localhost', window.location.hostname)
}
export function rebuildProjecties() {
    return fetch(`${backofficeRootUri}/projecties/rebuild`, {
        method: 'POST',
        credentials: "include",
        headers: {
            'Content-Type': 'application/json' // or 'application/x-www-form-urlencoded' depending on your server setup
        },
    });
}
export function resetData() {
    return fetch(`${backofficeRootUri}/reset`, {
        method: 'POST',
        credentials: "include",
        headers: {
            'Content-Type': 'application/json' // or 'application/x-www-form-urlencoded' depending on your server setup
        },
    });
}
export function fetchProjectionStates() {
    return fetch(`${backofficeRootUri}/projecties`, {
        method: 'GET',
        credentials: "include",
        headers: {
            'Content-Type': 'application/json' // or 'application/x-www-form-urlencoded' depending on your server setup
        },
    });
}