import {defineStore} from 'pinia'
import {Ref, ref} from "vue";
import {fetchKlanten, postKlant} from "@/lib/api";
import {useNotificationStore} from "@/stores/notifications";
import {validators} from "@/lib/validators";
import {useVuelidate} from "@vuelidate/core";
import {Klant} from "@/stores/models/klant";

export const useKlantenStore = defineStore('klanten',
    () => {
        const notificationsStore = useNotificationStore();

        const showKlantRegistratie = ref(false);
        const klanten : Ref<Klant[]> = ref([])
        const zoekbalk = ref("")

        const defaultKlant = () => ({
            id: '',
            naam: '',
            voornaam: '',
            email: '',
            opmerkingen: '',
        });
        let onNieuwKlantSuccess: Function | null = null;
        const nieuwKlant = ref(defaultKlant())
        const geselecteerdeKlant: Ref<Klant> = ref({
            id: '',
            naam: '',
            voornaam: '',
            email: '',
            opmerkingen: ''
        })
        const rules = {
            naam: {
                required: validators.required
            },
            voornaam: {
                required: validators.required
            },
            email: {
                required: validators.required,
                email: validators.email,
            },
        }
        const v$ = useVuelidate(rules, nieuwKlant)

        async function loadKlanten(sequence: string) {
            try {

                const response = await fetchKlanten(sequence)
                if (!response.ok)
                    return;

                const data = await response.json()
                klanten.value = data;
            } catch (e) {
                notificationsStore.addError('Fout bij het ophalen van de klanten', e)
            }
        }

        async function registreerKlant() {
            try {
                await v$.value.$validate()
                // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                if (v$.value.$invalid)
                    return false

                const response = await postKlant(nieuwKlant.value)
                const geregistreerdeKlant = await response.json();
                await loadKlanten(geregistreerdeKlant.sequence);

                nieuwKlant.value = defaultKlant();
                v$.value.$reset();

                notificationsStore.addSuccess('Klant toegevoegd', 'Klant succesvol toegevoegd.')

                showKlantRegistratie.value = false;

                const value = klanten.value.find((x:Klant) => x.id === geregistreerdeKlant.id);
                if(value) {
                    geselecteerdeKlant.value = value;
                    if(onNieuwKlantSuccess)
                        onNieuwKlantSuccess(geselecteerdeKlant.value)
                }
                return geregistreerdeKlant
            } catch (e) {
                notificationsStore.addError('Klant toevoegen mislukt', e)
            }
        }

        async function startKlantRegistratie(onSuccess: Function) {
            nieuwKlant.value = defaultKlant();
            onNieuwKlantSuccess = onSuccess
            showKlantRegistratie.value = true;
        }

        async function annuleerKlantRegistratie() {
            showKlantRegistratie.value = false;
            nieuwKlant.value = defaultKlant();
        }

        return {
            loadKlanten,
            klanten,
            nieuwKlant,
            geselecteerdeKlant,
            zoekbalk,
            showKlantRegistratie,
            v$,
            registreerKlant,
            startKlantRegistratie,
            annuleerKlantRegistratie
        }
    })
