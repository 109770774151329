<template>
  <header class="absolute inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10">
    <div class="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
      <div class="flex flex-1 items-center gap-x-6">
        <button type="button" class="-m-3 p-3 md:hidden" @click="mobileMenuOpen = true">
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="h-5 w-5 text-gray-900" aria-hidden="true" />
        </button>
        <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company" />
      </div>
      <nav class="hidden md:flex md:gap-x-11 md:text-sm md:font-semibold md:leading-6 md:text-gray-700">
        <router-link v-for="(item, itemIdx) in navigation" :key="itemIdx" :to="item.href">
        <a >{{ item.name }}</a>
        </router-link>
      </nav>
      <div class="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
        <div class="w-full max-w-lg lg:max-w-xs">
          <label for="search" class="sr-only">Search</label>
          <div class="relative text-gray-400 focus-within:text-gray-600">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon class="h-5 w-5" aria-hidden="true" />
            </div>
            <input id="search" v-model="depositStore.zoekbalk" class="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:text-sm sm:leading-6" placeholder="Search" type="search" name="search" />
          </div>
        </div>
      </div>

      <div class="flex flex-1 items-center justify-end gap-x-8">
        <button type="button" class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
          <span class="sr-only">View notifications</span>
          <BellIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <!-- Profile dropdown -->
        <Menu as="div" class="relative ml-3 flex-shrink-0">
          <div>
            <MenuButton class="relative flex rounded-full bg-indigo-600 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
              <span class="absolute -inset-1.5" />
              <span class="sr-only">Open user menu</span>
              <img class="h-8 w-8 rounded-full" v-if="userStore.user.imageUrl" :src="userStore.user.imageUrl" alt="" />

              <span v-else class="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
    <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
    </svg>
  </span>
            </MenuButton>
          </div>
          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <MenuItem v-for="item in userNavigation"  :key="item.name" v-slot="{ active }">
                <a :href="item.href" @click.prevent="item.onClick" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">{{ item.name }}</a>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>

      </div>
    </div>
    <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
      <div class="fixed inset-0 z-50" />
      <DialogPanel class="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-white px-4 pb-6 sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-gray-900/10">
        <div class="-ml-0.5 flex h-16 items-center gap-x-6">
          <button type="button" class="-m-2.5 p-2.5 text-gray-700" @click="mobileMenuOpen = false">
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
          <div class="-ml-0.5">
            <a href="#" class="-m-1.5 block p-1.5">
              <span class="sr-only">Your Company</span>
              <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" />
            </a>
          </div>
        </div>
        <div class="mt-2 space-y-2">
          <router-link v-for="item in navigation" :key="item.name" :to="item.href">
          <a class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ item.name }}</a>
          </router-link>
        </div>
      </DialogPanel>
    </Dialog>
  </header>

  <main>
    <div class="relative isolate overflow-hidden pt-16">
      <!-- Secondary navigation -->
      <header class="pb-4 pt-6 sm:pb-6">
        <div class="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
          <h1 class="text-base font-semibold leading-7 text-gray-900">{{ route.name }}</h1>
          <div class="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
            <router-link v-for="item in secondaryNavigation" :key="item.name" :to="item.href">
            <a :class="item.current ? 'text-indigo-600' : 'text-gray-700'">{{ item.name }}</a>
            </router-link>
          </div>
          <a href="/projecten/nieuw" class="ml-auto flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <PlusSmallIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
            Nieuw voorschot
          </a>
        </div>
      </header>

      <div class="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50" aria-hidden="true">
        <div class="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]" style="clip-path: polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)" />
      </div>
    </div>

    <div class="">
      <!-- Recent activity table -->
      <div>
        <div class="mt-6 overflow-hidden">
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
<router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <div aria-live="assertive" class="z-50 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition-group enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-for="notification in notificationsStore.notifications" :key="notification.id" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon v-if="notification.type === 'success'" class="h-6 w-6 text-green-400" aria-hidden="true" />
                <ExclamationCircleIcon v-else class="h-6 w-6 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">{{notification.title}}</p>
                <p class="mt-1 text-sm text-gray-500">{{notification.message}}</p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button type="button" @click="notificationsStore.clearNotification(notification.id)" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
  <CustomerRegistration></CustomerRegistration>
  <AppointmentRegistration></AppointmentRegistration>
</template>

<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {
  Bars3Icon,
  PlusSmallIcon,
} from '@heroicons/vue/20/solid'

const mobileMenuOpen = ref(false)


import {BellIcon, MagnifyingGlassIcon, XMarkIcon} from '@heroicons/vue/24/outline'
import {onBeforeMount} from 'vue'
import {useDepositsStore} from "@/stores/deposits";
import {useUserStore} from "@/stores/user";
import {signInWithGoogle} from "@/lib/api";
import {useRoute} from "vue-router";
import {useNotificationStore} from "@/stores/notifications";

const depositStore = useDepositsStore();
const userStore = useUserStore();
const notificationsStore = useNotificationStore();
const userNavigation = ref([]);
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline'
import CustomerRegistration from "@/components/CustomerRegistration.vue";
import AppointmentRegistration from "@/components/AppointmentRegistration.vue";

onBeforeMount(async () => {
  userNavigation.value = userStore.isLoggedIn ?
      loggedInNav : loggedOutNav
})
const navigation = [
  { name: 'Projecten', href: '/', current: true },
  { name: 'Klanten', href: '/klanten', current: false },
  { name: 'Admin', href: '/admin', current: false },
]

const route = useRoute();
const secondaryNavigation = [
  { name: 'Overzicht', href: '/', current: true },
]

const loggedInNav = [
  { name: 'Your Profile', href: '#', onClick: signInWithGoogle },
  { name: 'Settings', href: '#' , onClick: () => {},},
  { name: 'Sign out', href: '#' , onClick: () => {},},
];

const loggedOutNav = [
  { name: 'Sign In', href: '#', onClick: signInWithGoogle }];

</script>
