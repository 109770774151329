import {defineStore} from 'pinia'
import {Ref, ref} from "vue";
import {useNotificationStore} from "@/stores/notifications";
import {validators} from "@/lib/validators";
import {useVuelidate} from "@vuelidate/core";

export const useAfspraakRegistratieStore = defineStore('afspraak-registratie',
    () => {
        const notificationsStore = useNotificationStore();

        const showAfspraakRegistratie = ref(false);
        const defaultAfspraak = () => ({
            datum: '',
            van:'',
            tot: '',
            opmerkingen: '',
            voorschotbedrag: 50,
        });
        let onNieuwAfspraakSuccess: Function | null = null;
        const nieuwAfspraak = ref(defaultAfspraak())
        const rules = {
            van: {
                required: validators.required
            },
            tot: {
                required: validators.required
            },
            datum: {
                required: validators.required
            },
            voorschotbedrag: {
                required: validators.required
            },
        }
        const v$ = useVuelidate(rules, nieuwAfspraak, {$scope: 'klant'})

        async function registreerAfspraak() {
            try {
                await v$.value.$validate()
                // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                if (v$.value.$invalid)
                    return false

                if(onNieuwAfspraakSuccess)
                    onNieuwAfspraakSuccess(nieuwAfspraak.value)

                nieuwAfspraak.value = defaultAfspraak();
                v$.value.$reset();

                showAfspraakRegistratie.value = false;

                notificationsStore.addSuccess('Afspraak toegevoegd', 'Afspraak succesvol toegevoegd.')
            } catch (e) {
                notificationsStore.addError('Afspraak toevoegen mislukt', e)
            }
        }

        async function startAfspraakRegistratie(onSuccess: Function) {
            showAfspraakRegistratie.value = true;
            nieuwAfspraak.value = defaultAfspraak();
            onNieuwAfspraakSuccess = onSuccess
        }

        async function annuleerAfspraakRegistratie() {
            showAfspraakRegistratie.value = false;
            nieuwAfspraak.value = defaultAfspraak();
        }

        return {
            nieuwAfspraak,
            showAfspraakRegistratie,
            v$,
            startAfspraakRegistratie,
            registreerAfspraak,
            annuleerAfspraakRegistratie
        }
    })
