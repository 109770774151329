// validators.js
import {required, email, minLength, between} from '@vuelidate/validators';

const messages = {
    required: 'Dit veld is verplicht.',
    email: 'Voer een geldig e-mailadres in.',
    minLength: (min) => `Dit veld moet minimaal ${min} tekens lang zijn.`,
    between: (min, max) => `Dit veld moet tussen ${min} en ${max} liggen.`,
};

export const validators = {
    required: {
        ...required,
        $message: messages.required,
    },
    email: {
        ...email,
        $message: messages.email,
    },
    minLength: (min) => ({
        ...minLength(min),
        $message: messages.minLength(min),
    }),
    between: (min, max) => ({
        ...between(min, max),
        $message: messages.between(min, max),
    }),
};
