import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  role: "list",
  class: "mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
}
const _hoisted_2 = { class: "flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white" }
const _hoisted_3 = { class: "flex-1 truncate px-4 py-2 text-sm" }
const _hoisted_4 = { class: "font-medium text-gray-900 hover:text-gray-600" }
const _hoisted_5 = { class: "text-gray-500" }
const _hoisted_6 = { class: "flex-shrink-0 pr-2" }
const _hoisted_7 = {
  type: "button",
  class: "inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
}
const _hoisted_8 = { class: "bg-white shadow sm:rounded-lg" }
const _hoisted_9 = { class: "px-4 py-5 sm:p-6" }
const _hoisted_10 = { class: "mt-2 sm:flex sm:items-start sm:justify-between" }
const _hoisted_11 = { class: "mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center" }
const _hoisted_12 = {
  key: 0,
  class: "inline-block"
}
const _hoisted_13 = { class: "px-4 py-5 sm:p-6" }
const _hoisted_14 = { class: "mt-2 sm:flex sm:items-start sm:justify-between" }
const _hoisted_15 = { class: "mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center" }
const _hoisted_16 = {
  key: 0,
  class: "inline-block"
}

import {fetchProjectionStates, resetData, rebuildProjecties} from "@/lib/adminApi";
import {onMounted, ref} from "vue";
import {useNotificationStore} from "@/stores/notifications";


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminView',
  setup(__props) {


const notificationStore = useNotificationStore();

const isLoading = ref(false);

const projections = ref([]);

onMounted(async () => {
  let response = await fetchProjectionStates();

  projections.value = await response.json();
})

async function onRebuildClick() {
  try {
    isLoading.value = true
    await rebuildProjecties()
    notificationStore.addSuccess('Rebuild projecties', 'De projecties zijn gerebuild')
  } catch (e) {
    notificationStore.addError('Projecties rebuilden is mislukt', e);
  } finally {
    isLoading.value = false;
  }
}

async function onResetClicked() {
  try {
    isLoading.value = true
    await resetData()
    notificationStore.addSuccess('Reset data', 'De data is gereset')
  } catch (e) {
    notificationStore.addError('Data resetten is mislukt', e);
  } finally {
    isLoading.value = false;
  }
}


return (_ctx: any,_cache: any) => {
  const _component_EllipsisVerticalIcon = _resolveComponent("EllipsisVerticalIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "text-sm font-medium text-gray-500" }, "Pinned Projects", -1)),
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(projections.value, (project) => {
          return (_openBlock(), _createElementBlock("li", {
            key: project.shardName,
            class: "col-span-1 flex rounded-md shadow-sm"
          }, [
            _cache[1] || (_cache[1] = _createElementVNode("div", {
              class: _normalizeClass('flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white')
            }, null, -1)),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("a", _hoisted_4, _toDisplayString(project.shardName), 1),
                _createElementVNode("p", _hoisted_5, _toDisplayString(project.sequence), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("button", _hoisted_7, [
                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "sr-only" }, "Open options", -1)),
                  _createVNode(_component_EllipsisVerticalIcon, {
                    class: "h-5 w-5",
                    "aria-hidden": "true"
                  })
                ])
              ])
            ])
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "text-base font-semibold leading-6 text-gray-900" }, "Alle projecties rebuilden", -1)),
        _createElementVNode("div", _hoisted_10, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "max-w-xl text-sm text-gray-500" }, [
            _createElementVNode("p", null, "Hier komen alle projectie statussen")
          ], -1)),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", {
              class: "inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500",
              type: "button",
              onClick: _withModifiers(onRebuildClick, ["prevent"])
            }, [
              (isLoading.value)
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, _cache[3] || (_cache[3] = [
                    _createElementVNode("svg", {
                      class: "animate-spin h-5 w-5 text-white",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, [
                      _createElementVNode("circle", {
                        class: "opacity-25",
                        cx: "12",
                        cy: "12",
                        r: "10",
                        stroke: "currentColor",
                        "stroke-width": "4"
                      }),
                      _createElementVNode("path", {
                        class: "opacity-75",
                        d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z",
                        fill: "currentColor"
                      })
                    ], -1)
                  ])))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(isLoading.value ? 'Rebuilding...' : 'Rebuild projecties'), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _cache[8] || (_cache[8] = _createElementVNode("h3", { class: "text-base font-semibold leading-6 text-gray-900" }, "Alle data resetten", -1)),
        _createElementVNode("div", _hoisted_14, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "max-w-xl text-sm text-gray-500" }, [
            _createElementVNode("p", null, "Pas op!")
          ], -1)),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("button", {
              class: "inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500",
              type: "button",
              onClick: _withModifiers(onResetClicked, ["prevent"])
            }, [
              (isLoading.value)
                ? (_openBlock(), _createElementBlock("span", _hoisted_16, _cache[6] || (_cache[6] = [
                    _createElementVNode("svg", {
                      class: "animate-spin h-5 w-5 text-white",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, [
                      _createElementVNode("circle", {
                        class: "opacity-25",
                        cx: "12",
                        cy: "12",
                        r: "10",
                        stroke: "currentColor",
                        "stroke-width": "4"
                      }),
                      _createElementVNode("path", {
                        class: "opacity-75",
                        d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z",
                        fill: "currentColor"
                      })
                    ], -1)
                  ])))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(isLoading.value ? 'Resetting...' : 'Reset data'), 1)
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})