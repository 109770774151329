<script setup lang="ts">
import {onMounted} from "vue";
import {useUserStore} from "@/stores/user";
import {useRouter} from "vue-router";

const router = useRouter();
onMounted(async () => {
  const queryParams = new URLSearchParams(window.location.search);

// eslint-disable-next-line no-undef
  const userStore = useUserStore();

  await userStore.exchangeCode(queryParams.get('code'))
  if(userStore.isLoggedIn)
    await router.push({path: '/'})
});
</script>

<template>
  <p>Logging you in...</p>
</template>

<style scoped>

</style>